export function impactClickEvent(refId: string, bizType: string, snapshotId: string) {
  return new Promise((resolve, reject) => {
    try {
      console.info(snapshotId, refId)
      if (!snapshotId || !refId) {
        return resolve({})
      }
      const params = {
        refId,
        bizType,
        snapshotId,
      }
      http({
        url: '/pcapi/distribution/click',
        method: 'POST',
        body: params,
      })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    }
    catch (error) {
      reject(error)
    }
  })
}

export function isValidURL(string: string) {
  try {
    const urlObject = new URL(string)
    return true
  }
  catch (error) {
    return false
  }
}
